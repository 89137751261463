@import '../variables.css';

header {
	max-width: 40em;
	text-align: left;
	padding-left: constant(safe-area-inset-left);
	padding-left: env(safe-area-inset-left);
	padding-right: constant(safe-area-inset-right);
	padding-right: env(safe-area-inset-right);
	margin: var(--space-l2);

	.no-cssanimations & {
		opacity: 1;
	}

	a {
		color: var(--text-color);
	}

	.nav,
	.desc {
		margin: var(--space-l2) 0;
	}

	.nav {
		h1,
		h2,
		& > a {
			display: inline;
			font-size: 2em;
			padding: 0;
			margin: 0;
			white-space: nowrap;
		}

		& > a {
			transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, padding-left 0.3s ease, margin-left 0.3s ease;
			font-weight: 300;
			color: var(--secondary-large-text-color);
			padding-left: 0.15em;
			border-radius: 5px;
			border: 0.07em solid transparent;
			margin-inline-start: calc(-0.35em + -0.15em);
			margin-inline-end: 0.25em;

			&::before {
				content: '';
				display: inline-block;
				transform: rotate(-135deg);
				position: relative;
				top: 0.6em;
				height: 0.35em;
				width: 0.35em;
				vertical-align: top;
				border-style: solid;
				border-width: 0.07em 0.07em 0 0;
			}

			&::after {
				transition: opacity 0.3s ease;
				content: '';
				position: relative;
				left: 0;
				margin-inline-start: 0.4em;
				border-right: 0.06em solid;
				border-radius: 10px;
			}
		}

		h1 > a,
		h2 > a {
			@media (any-hover) {
			&:hover {
				color: var(--blue);
			}

			&:active {
				color: var(--blue);
			}
			}
		}

		.js &.has-page-nav { /* Dropdown will only with with JS, so don't hint it's clickable/focusable without it working. */
			position: relative;

			h1, h2 {
				transition: padding-left 0.3s ease, padding-right 0.3s ease, box-shadow 0.3s ease; /* Only for focused state. */
				position: relative;
				cursor: pointer;
				/* To prepare focus transtion: */
				box-shadow: 0 0 0 0.07em transparent;
				border-radius: 5px;

				&::after {
					transition: transform 0.3s ease-out;
					content: '';
					display: inline-block;
					position: relative; /* Position relative allows arrow to wrap to new line before text. */
					top: -0.15em;
					right: -0.25em;
					height: 0;
					width: 0;
					border-left: 0.4rem solid transparent;
					border-right: 0.4rem solid transparent;
					border-top: 0.4rem solid var(--text-color);
				}

				&:focus {
					padding-left: 0.25em;
					padding-right: 0.5em;
					box-shadow: 0 0 0 0.07em var(--turquoise);
					outline: none;
				}

				&.clicked:focus {
					padding-left: 0;
					padding-right: 0;
					box-shadow:none;
				}

				&.shown::after {
					transform: rotateX(180deg);
				}
			}
		}
	}

	.nav > a {
		@media (any-hover) {
		&:hover {
			color: var(--blue);
			background-color: var(--action-background-l2);
			margin-left: 0;

			&::after {
				opacity: 0;
			}
		}

		&:active {
			color: var(--blue-secondary);
			background-color: var(--action-background-l3);
			margin-left: 0;

			&::after {
				opacity: 0;
			}
		}
		}

		&:focus {
			color: var(--blue-secondary);
			background-color: var(--action-background-l3);
			padding-left: 0.25em;
			border-color: var(--blue-secondary);
			outline: none;
			margin-left: 0;

			&::after {
				opacity: 0;
			}
		}
	}

	.desc {
		font-size: 1.25em;
		line-height: 1.5;

		p {
			margin: 0;
		}

		img {
			height: auto;
			max-width: 100%;
		}

		a {
			color: var(--blue);

			@media (any-hover) {
			&:hover {
				color: var(--blue-secondary);
			}

			&:active {
				color: var(--darkgrey);
			}
			}
		}
	}
}

.page-nav {
	transition: max-height 0.3s, border-color 0.3s, box-shadow 0.3s, margin-top 0.3s;
	transition-timing-function: ease-out, ease, ease, ease, ease;
	display: block;
	overflow: hidden;
	max-height: 0;
	width: 100%;
	font-size: 2em;
	list-style: none;
	border-top: 0 solid transparent;
	border-bottom: 0 solid transparent;
	z-index: 2;


	&.shown {
		transition-timing-function: ease-in, ease, ease;
		max-height: 4.6em;
		border-width: 1px;
		border-color: var(--divider-color);
		margin-top: 1rem;
	}

	a {
		color: var(--secondary-text-color);

		@media (any-hover) {
		&:hover,
		&:focus {
			color: var(--blue);
		}
		}
	}
}

.content {
	max-width: 55em;
	text-align: center;
	padding-left: constant(safe-area-inset-left);
	padding-left: env(safe-area-inset-left);
	padding-right: constant(safe-area-inset-right);
	padding-right: env(safe-area-inset-right);
	margin: var(--space-l3) var(--space-l2);

	.no-cssanimations & {
		opacity: 1;
	}
}

.license {
	display: block;
	max-width: 55em;
	text-align: left;
	color: var(--darkgrey);
	margin: var(--space-l3) var(--space-l2);

	p {
		max-width: 15em;
		font-size: 0.9em;
		margin: 0;
	}

	a {
		transition: color 0.3s ease;
		color: var(--darkgrey);

		@media (any-hover) {
		&:hover {
			color: var(--blue);
		}

		&:active {
			color: var(--blue-secondary);
		}
		}
	}
}

/* Tablet */
@media (--width-l2) {
	header {
		width: 80%;
		margin: var(--space-l3) auto;

		.nav {
			h1,
			h2,
			& > a {
				font-size: 2.5em;
			}

			& > a {
				padding-left: 0.5em;
				margin-left: calc(-0.45em + -0.5em);
				margin-right: 0.35em;

				&::before {
					top: 0.55em;
					left: -0.15em;
					height: 0.45em;
					width: 0.45em;
				}

				&:focus {
					padding-left: 0.5em;
				}
			}
		}

		.desc {
			font-size: 1.5em;
		}
	}

	.page-nav {
		display: inline-block;
		position: absolute;
		top: 1.35em; /* Magic numbers that… */
		left: 5.9em; /* work really well    */
		width: auto;
		font-size: 2.5em;
		padding: 0 0.25em;
		background-color: rgba(235, 238, 240, 0.95);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
		border-top: none;
		border-bottom: 0 solid rgba(0, 153, 204, 0);

		@media (--width-l2) (prefers-color-scheme: dark) {
			background-color: rgba(32, 32, 32, 0.95);
		}

		&.shown {
			border-width: 3px;
			border-color: var(--blue);
			margin-top: 0;
		}
	}

	.content {
		margin-right: auto;
		margin-left: auto;
	}

	.license {
		width: 80%;
		margin-right: auto;
		margin-left: auto;
	}
}

@supports ((backdrop-filter:none)) {
	.page-nav {
		backdrop-filter: blur(10px);
		background-color: rgba(235, 238, 240, .65);

		@media (prefers-color-scheme: dark) {
			background-color: rgba(32, 32, 32, 0.85);
		}
	}
}
